import React from "react";
import { Link } from "gatsby";

import SEO from "../components/SEO";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>404: NOT FOUND</h1>
    <Link to="/">Go to main page</Link>
  </>
);

export default NotFoundPage;
